/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FavouriteIconProps {
  selected: boolean;
  onClick(e?): void;
}

const StyledFavouriteIcon = styled(FontAwesomeIcon)<{ selected: boolean }>`
  height: 30px;
  width: 30px;
  color: ${(props) => (props.selected ? '#f7b538' : cssVars.lightGrey)};
  // color: ${cssVars.lightGrey};
  //stroke: ${cssVars.yellow};
  //stroke-width: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  //--fa-primary-color: ${(props) => (props.selected ? '#f7b538' : cssVars.silverGrey)};
  //--fa-secondary-color: ${cssVars.lightGrey};
  //--fa-secondary-color: ${(props) => (props.selected ? cssVars.lightBlue : cssVars.lightGrey)};
  :hover {
    transform: scale(1.2);
  }
`;

function FavouriteIcon(props: FavouriteIconProps) {
  return (
    <StyledFavouriteIcon
      icon={['fas', 'star']}
      // icon={['fad', 'circle-star']}
      selected={props.selected}
      onClick={(e) => props.onClick(e)}
    />
  );
}

export default FavouriteIcon;
