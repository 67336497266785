/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Tooltip from '@atoms/Tooltip/Tooltip';
import TooltipButton from '@atoms/TooltipButton';
import { useWindowDimensions } from '@common/application/hooks/useWindowDimensions';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentStatus } from '@molecules/DocumentSummary';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTooltipTriggerState } from 'react-stately';

const currentColor = (props) => (props.filter === props.typeId ? cssVars.blue : cssVars.darkGrey);

const StyledScheduleItem = styled.span<ItemProps & { screenWidth: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${(props) => (props.filter === props.typeId ? cssVars.blue : cssVars.darkGrey)};
  position: relative;
  transition: all 0.2s ease;
  cursor: ${(props) => (!!props.setFilter ? 'pointer' : 'inherit')};
  z-index: 3;
  padding: ${(props) => (props.screenWidth > 1024 ? 8 : 4)}px;
  font-size: ${(props) => (props.screenWidth > 1024 ? 16 : 12)}px;
  height: 100%;
  //max-width: 20%;

  @media (max-width: 1024px) {
    color: ${(props) => (props.dark ? (props.filter === props.typeId ? cssVars.blue : cssVars.offWhite) : props.filter === props.typeId ? cssVars.blue : cssVars.darkGrey)};
  }
  > svg {
    transition: all 0.2s ease;
    height: ${(props) => (props.screenWidth > 1024 ? 24 : 14)}px;
    //height: 24px;
  }

  > span {
    text-align: center;
    display: flex;
    gap: 6px;
    @media (max-width: 1024px) {
      gap: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    color: ${(props) => (props.filter === props.typeId || !props.setFilter ? currentColor(props) : cssVars.black)};
    > svg {
      scale: ${(props) => (props.filter === props.typeId || !props.setFilter ? 1 : 1.15)};
    }
  }
`;

const StyledUnderline = styled(motion.div)`
  position: absolute;
  inset: 1px;
  z-index: 2;
  border-block: 2px solid ${cssVars.blue};
  box-sizing: border-box;
`;

interface ItemProps {
  typeId: DocumentStatus;
  count: number;
  setFilter?: Dispatch<SetStateAction<DocumentStatus>>;
  filter?: DocumentStatus;
  dark?: boolean;
  hideLabel?: boolean;
}

function ScheduleItem(props: ItemProps) {
  const { width } = useWindowDimensions();
  const typeConfig = {
    [DocumentStatus.CREATED]: {
      icon: <FontAwesomeIcon icon={['fal', 'file-plus']} />,
      label: <FormattedMessage defaultMessage="Created" description="Created date label label" />,
    },
    [DocumentStatus.PROVIDED]: {
      icon: <FontAwesomeIcon icon={['fal', 'paper-plane']} />,
      label: <FormattedMessage defaultMessage="Provided to Customer" description="Document has been sent to the customer" />,
    },
    [DocumentStatus.ACCEPTED]: {
      icon: <FontAwesomeIcon icon={['fal', 'file-signature']} />,
      label: <FormattedMessage defaultMessage="Approved" description="Signed document has been approved by the customer" />,
    },
    [DocumentStatus.NONE]: {
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      label: <FormattedMessage defaultMessage="No Status" description="Documents that have not been given a status (e.g. created, provided to customer etc.) yet" />,
    },
    [DocumentStatus.ALL]: {
      icon: <FontAwesomeIcon icon={['fal', 'file']} />,
      label: <FormattedMessage defaultMessage="Total" description="Label for the total number of documents associated with an account" />,
    },
    [DocumentStatus.REJECTED]: {
      icon: <FontAwesomeIcon icon={['fal', 'file-slash']} />,
      label: <FormattedMessage defaultMessage="Rejected" description="Not Accepted" />,
    },
  };

  if (props.typeId === DocumentStatus.NONE && !props.count) {
    return null;
  }

  const icon = typeConfig[props.typeId]?.icon;

  return (
    <StyledScheduleItem screenWidth={width} {...props} onClick={!props.setFilter ? null : () => props.setFilter(props.typeId)}>
      {!!props.setFilter && icon}
      <span
        css={css`
          white-space: nowrap;
        `}
      >
        {!props.setFilter && (props.hideLabel ? <TooltipButton tooltip={typeConfig[props.typeId]?.label}>{icon}</TooltipButton> : icon)}
        {props.hideLabel || (!props.setFilter && width < 1024) ? null : (
          <span
            css={css`
              //margin-left: 6px;
            `}
          >
            {typeConfig[props.typeId]?.label}
          </span>
        )}
        <span
          css={css`
            font-weight: bold;
            //margin-left: 6px;
          `}
        >
          {props.count}
        </span>
      </span>
      {props.filter === props.typeId && <StyledUnderline layoutId={'underline'} />}
    </StyledScheduleItem>
  );
}

export default ScheduleItem;
