/** @format */

import DateTimeTableCellFactory from '@atoms/DateTimeTableCellFactory';
import ErrorBoundary from '@atoms/ErrorBoundary/ErrorBoundary';
import FavouriteIcon from '@atoms/FavouriteIcon';
import FlexBox from '@atoms/FlexBox';
import LoadingSpinner from '@atoms/LoadingSpinner';
import OpenSpan from '@atoms/OpenSpan';
import TableImage from '@atoms/TableImage';
import Text from '@atoms/Text';
import useSecureQuery from '@common/application/auth/useSecureQuery';
import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import { TableKey } from '@common/application/enums/TableKey';
import useSearchableTable from '@common/application/hooks/useSearchableTable/useSearchableTable';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { AccountClient } from '@common/model/apiClient';
import { User } from '@common/model/apiClient/User';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import DocumentSummary, { DocumentStatus } from '@molecules/DocumentSummary';
import SearchableTable from '@organisms/SearchableTable';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Row as RowType } from 'react-table';

const tableKey = TableKey.ACCOUNT;

export const ClampedTableRow = styled.span`
  max-height: 48px;
  min-width: 300px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
  //line-height: 20px;
  -webkit-line-clamp: 2;
  z-index: 1;
  transition: max-height 1s ease;

  &:hover {
    display: flex;
    max-height: 400px;
  }
`;

function AccountsTable(props: AccountsTableProps) {
  const { tableStrings, actionStrings } = useStrings();
  const { search, setSearch, pit, setPit, nextPage, previousPage, sort, setSort, searchAfter, pageHistory } = useSearchableTable(tableKey, { throttle: true });
  const session = useSecureSession<BaseRealmRole, BaseRole>();
  const router = useRouter();
  const intl = useIntl();
  const { selectedCurrency } = useContext(CurrencyContext);

  // Queries
  const { data, isLoading, isFetching } = useQuery(
    ['accounts_home_search', search, props.search, props.size, searchAfter, sort],
    () => AccountClient.list(props.search ?? search, sort, { deleted: false }, props.size ?? 10, pit, searchAfter),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      keepPreviousData: true,
      onSuccess: () => {
        // console.log('On success callback triggered: ', Date.now());
      },
    },
  );

  useEffect(() => setPit(data), [data, setPit]);

  const scoreColumn = useMemo(
    () =>
      search === '' || process.env.NODE_ENV !== 'development'
        ? []
        : [
            {
              Header: 'Match',
              accessor: '_score',
              Cell: ({ value }) => (parseFloat(value).toFixed(2) === 'NaN' ? '' : parseFloat(value).toFixed(2)),
            },
          ],
    [search],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        Header: () => <div style={{ textAlign: 'center', width: '100%' }}>{tableStrings.logo}</div>,
        id: 'logo',
        accessor: '_source.logo',
        disableSortBy: true,
        Cell: ({ value }) => <TableImage resource={value} />,
      },
      {
        Header: tableStrings.name,
        accessor: '_source.name', // accessor is the "key" in the data
        sortId: 'name.keyword',
        id: 'name',
        cardTitle: true,
      },
      {
        Header: tableStrings.description,
        accessor: '_source.description',
        id: 'description',
        disableSortBy: true,
        Cell: ({ value }) => <ClampedTableRow>{value}</ClampedTableRow>,
        cardRender: ({ value }) => <span>{value}</span>,
        // cardRender: (data) => <span>{data.description}</span>,
      },
      {
        Header: <FormattedMessage defaultMessage={'Total'} description="Document total label" />,
        id: 'savingsTotal',
        sortId: 'savingsTotal',
        accessor: '_source.savingsTotal',
        Cell: ({ row }) => {
          return intl.formatNumber(Number(row.original?._source?.savingsTotal), {
            style: 'currency',
            currency: selectedCurrency?.id,
          });
        },
      },
      {
        Header: tableStrings.accountNumber,
        accessor: '_source.accountNumber',
        id: 'accountNumber',
        sortId: 'accountNumber.keyword',
      },
      {
        Header: tableStrings.nationalAccountNumber,
        accessor: '_source.nationalAccountNumber',
        sortId: 'nationalAccountNumber.keyword',
        id: 'nationalAccountNumber',
      },
      {
        Header: tableStrings.createdAt,
        accessor: '_source.createdAt',
        id: 'createdAt',
        sortId: 'createdAt',
        Cell: DateTimeTableCellFactory,
        cardRender: DateTimeTableCellFactory,
        // cardRender: (data) => DateTimeCardFactory(data.createdAt),
      },
      {
        Header: tableStrings.summary,
        id: 'summary',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div
              css={css`
                font-size: 12px;
                padding-inline: 8px;

                svg {
                  height: 15px !important;
                }
              `}
            >
              <DocumentSummary
                hideLabels={true}
                summary={[
                  { documentStatus: DocumentStatus.CREATED, count: row.original._source.createdCount ?? 0 },
                  { documentStatus: DocumentStatus.PROVIDED, count: row.original._source.providedCount ?? 0 },
                  { documentStatus: DocumentStatus.ACCEPTED, count: row.original._source.acceptedCount ?? 0 },
                  { documentStatus: DocumentStatus.ALL, count: row.original._source.totalCount ?? 0 },
                  { documentStatus: DocumentStatus.NONE, count: row.original._source.orphanedCount ?? 0 },
                ]}
              />
            </div>
          );
        },
        cardRender: ({ row: data }) => {
          return (
            <div
              css={css`
                font-size: 12px;
                //padding-inline: 8px;
                display: flex;
                justify-content: flex-start;
                svg {
                  height: 15px !important;
                }
              `}
            >
              <DocumentSummary
                summary={[
                  { documentStatus: DocumentStatus.CREATED, count: data.createdCount ?? 0 },
                  { documentStatus: DocumentStatus.PROVIDED, count: data.providedCount ?? 0 },
                  { documentStatus: DocumentStatus.ACCEPTED, count: data.acceptedCount ?? 0 },
                  { documentStatus: DocumentStatus.ALL, count: data.totalCount ?? 0 },
                  { documentStatus: DocumentStatus.NONE, count: data.orphanedCount ?? 0 },
                ]}
              />
            </div>
          );
        },
      },
      {
        Header: tableStrings.actions,
        id: 'actions',
        Cell: ({ row }) => {
          return (
            <OpenSpan
              onClick={(e) => {
                router.replace(`/?q=${search}`);
                e.stopPropagation();
              }}
            >
              <Text.Link href={`/account/${row.original._source.id}`}>{actionStrings.open}</Text.Link>
            </OpenSpan>
          );
        },
      },
      ...scoreColumn,
    ];
    if (props.favouriteAccounts) {
      // @ts-ignore
      cols.unshift({
        Header: '',
        id: 'fav',
        Cell: ({ row }) => (
          <FavouriteIcon
            selected={props.favouriteAccounts.includes(row.original._source.id)}
            onClick={(e) => {
              e.stopPropagation();
              const accountId = row.original._source.id;
              props.toggleFavourite(accountId);
            }}
          />
        ),
      });
    }
    return cols;
  }, [router, scoreColumn, search, intl, selectedCurrency, props.favouriteAccounts]);

  return (
    <ErrorBoundary>
      {isLoading ? (
        <FlexBox
          justifyContent={'center'}
          alignItems={'center'}
          css={css`
            height: 100%;
            width: 100%;
          `}
        >
          <LoadingSpinner
            css={css`
              svg {
                width: 40px;
                height: 40px;
              }
            `}
          />
        </FlexBox>
      ) : (
        <SearchableTable
          loading={isFetching}
          throttle
          search={search}
          setSearch={setSearch}
          title={<FormattedMessage defaultMessage={'Search Accounts'} description={'Home page title'} />}
          tableKey={tableKey}
          columns={columns}
          data={data as { payload: Record<string, unknown>[]; last_page: number; total: { value: number } }}
          pageHistory={pageHistory}
          nextPage={nextPage}
          previousPage={previousPage}
          sort={sort}
          onSort={setSort}
          onClickRow={(row: RowType & { original: { _source: AccountClient; sort: any } }) => {
            router.push(`/?q=${search}`);
            router.push(`/account/${row.original._source.id}`);
          }}
          href={(row: RowType & { original: { _source: AccountClient; sort: any } }) => `/account/${row.original._source.id}`}
          allowRenderToCard={true}
          hideTable={props.hideTable && search.length < 2}
        />
      )}
    </ErrorBoundary>
  );
}

interface AccountsTableProps {
  size?: number;
  search?: string;
  setSearch?: Dispatch<SetStateAction<string>>;
  controlled?: boolean;
  hideTable?: boolean;
  favouriteAccounts?: Array<number>;
  toggleFavourite?(accountId: number): void;
}

export default AccountsTable;
